import React, { useEffect, useState } from "react";
import Head from "next/head";
import getConfig from "next/config";

import { TPage } from '@JohnLewisPartnership/jlfs-ui-library/dist/client/ContentfulClient';
import Analytics, {
  setJLData,
} from "@JohnLewisPartnership/jlfs-ui-library/dist/components/analytics/Analytics";
import LiveChat from "@JohnLewisPartnership/jlfs-ui-library/dist/components/liveChat/liveChat";
import { structuredDataElement } from '@JohnLewisPartnership/jlfs-ui-library/dist/utils/structured-data/structuredDataElement';
import { renderer } from "../utils/renderer"

export default function RootLayout({
  children,
  pageProps,
}: {
  children: React.ReactNode;  
  pageProps: TPage | any;
}) {
  const {
    footer,
    header,
    login,
    structuredData,
    metadata
  } = pageProps;

  const [currentUrl, setCurrentUrl] = useState<string>();
  const { publicRuntimeConfig } = getConfig();

  let robotsContent = [];
  if (metadata.noIndex) {
    robotsContent.push("noindex");
  }
  if (metadata.noFollow) {
    robotsContent.push("nofollow");
  }
  if (header && login) {
    header.fields["login"] = login;
  }

  const hasChat =
    publicRuntimeConfig.chatAPIKey &&
    publicRuntimeConfig.chatName &&
    metadata.slug === "home-insurance";

  useEffect(() => {
    setCurrentUrl(window.location.href);
    setJLData(metadata);
  }, [metadata]);

  return (
    <>
      <Head>
        {metadata.title && <title>{metadata.title}</title>}
        {metadata.description && <meta name="description" content={metadata.description} />}
        <link
          rel="canonical"
          href={
            // deepcode ignore DOMXSS
            metadata.canonicalUrl || currentUrl
          }
        />
        {robotsContent.length > 0 && (
          <meta name="robots" content={robotsContent.join(",")} />
        )}
        {hasChat && (
          <LiveChat
            apiKey={publicRuntimeConfig.chatAPIKey}
            name={publicRuntimeConfig.chatName}
          />
        )}
        {structuredData?.map( (data:{payload:unknown, key:string} ) => 
          structuredDataElement(data.payload, data.key)        
        )}
      </Head>
      <Analytics />
      {header && renderer(header, pageProps.preview)}
      <main>{children}</main>
      {footer && renderer(footer, pageProps.preview)}
    </>
  );
}
