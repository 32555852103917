import ContentfulRegistry from "@JohnLewisPartnership/jlfs-ui-library/dist/registry/ContentfulRegistry";
import wrappers from "@JohnLewisPartnership/jlfs-ui-library/dist/components/wrappers";
import contentfulRenderer from "@JohnLewisPartnership/jlfs-ui-library/dist/renderer/ContentfulRenderer";

export const registry = new ContentfulRegistry();
registry.registerMany(wrappers);

export const renderer = (nodes: any, preview: boolean = false) => {
  const theRenderer = new contentfulRenderer(registry, preview);
  return theRenderer.render(nodes);
}; 
